@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
$fa-font-path: "~@fortawesome/fontawesome-pro/webfonts";
@import "~@fortawesome/fontawesome-pro/scss/light";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/solid";
@import "~@fortawesome/fontawesome-pro/scss/brands";

@import "scss/variables";
@import "scss/theme";
@import "scss/snackbar";

html,
body {
  height: 100%;
  @include theme(background-color, primary);
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.d-flex {
  display: flex;

  &.align-center {
    align-items: center;
  }

  &.-col {
    flex-direction: column;
  }

  &.-left {
    align-items: flex-start !important;
  }
}

mat-icon {
  &.small {
    font-size: 12px;
    height: 12px;
    width: 12px;
  }
}

.page {
  padding: 1rem;

  &-icon {
    font-size: 3rem;
  }
}

.padding-0 {
  padding: 0 !important;
}

.margin-0 {
  margin: 0 !important;
}

.spacer {
  flex: 1 1 auto;
}

.txt {
  &-secondary,
  &-accent {
    color: mat-color($admin-tool-accent);
  }

  &-primary {
    color: mat-color($admin-tool-primary);
  }
}

.form-field {
  mat-form-field {
    width: 100%;
  }

  &.permission {
    margin: 1rem 0;
  }
}

.info-container {
  width: 100%;
  margin: 0 -1rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 600px) {
    width: calc(100% + 2rem);
    flex-direction: row;
  }

  .info-item {
    width: 100%;
    padding: 1rem;
  }
}

.button-container {
  display: flex;
  justify-content: space-between;

  &.card-header {
    padding: 1rem 0;

    @media screen and (min-width: 600px) {
      padding: 0.5rem;
    }
  }
}

.permission-icon {
  padding-right: 0.5rem;
}
