@import "../scss/variables";

snack-bar-container {
  &.error {
    color: mat-contrast($custom-warn, 600) !important;
    background-color: mat-color($custom-warn, 600) !important;

    .mat-simple-snackbar-action {
      color: mat-contrast($custom-warn, 600) !important;
    }
  }

  &.success {
    color: mat-contrast($custom-primary, 700) !important;
    background-color: mat-color($custom-primary, 700) !important;

    .mat-simple-snackbar-action {
      color: mat-contrast($custom-primary, 700) !important;
    }
  }
}
