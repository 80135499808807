@import "~@angular/material/theming";
@include mat-core();

$custom-primary: (
  50: #e3f9ed,
  100: #bcf1d1,
  200: #8ce7b4,
  300: #4cde95,
  400: #00d57c,
  500: #00cb66,
  600: #00bb5b,
  700: #00a84e,
  800: #009642,
  900: #00752d,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  ),
);

$custom-secondary: (
  50: #e2e5ea,
  100: #b6bece,
  200: #8894ac,
  300: #5c6c8c,
  400: #3c4f77,
  500: #173564,
  600: #112f5d,
  700: #072753,
  800: #011e47,
  900: #000e30,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  ),
);

$custom-warn: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #f44336,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #b71b1c,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  ),
);

$admin-tool-primary: mat-palette($custom-primary, 300);
$admin-tool-accent: mat-palette($custom-secondary, 900);

$admin-tool-warn: mat-palette($custom-warn);

$admin-tool-theme-light: mat-light-theme(
  (
    color: (
      primary: $admin-tool-primary,
      accent: $admin-tool-accent,
      warn: $admin-tool-warn,
    ),
  )
);

$admin-tool-theme-dark: mat-dark-theme(
  (
    color: (
      primary: $admin-tool-primary,
      accent: $admin-tool-accent,
      warn: $admin-tool-warn,
    ),
  )
);

@mixin theme($property: null, $key: null) {
  & {
    #{$property}: mat-color(map_get($admin-tool-theme-light, $key));
  }

  .dark-mode & {
    #{$property}: mat-color(map_get($admin-tool-theme-dark, $key));
  }
}
